import React from 'react'

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App content
import Smile4 from '../../assets/patientPics/smile4.jpg'
import Smile5 from '../../assets/patientPics/smile5.jpg'

// App utils
import { themeStyles, metrics, presets } from '../../utils/theme'
import typography from '../../utils/typography'

const typographyJson = typography.toJSON()

const styles = {
  ol: {
    counterReset: 'counter',
    listStyle: 'none',
  },
  olli: {
    position: 'relative',
    ':before': {
      content: `counter(counter) ". "`,
      counterIncrement: 'counter',
      fontSize: typographyJson.h3.fontSize,
      color: typographyJson.h3.color,
      fontWeight: typographyJson.h3.fontWeight,
      display: 'inline-block',
      position: 'absolute',
      left: '-1em',
    },
  },
}

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle title="Retainers" bannerKey={'ORTHODONTICS'} />
          <div css={themeStyles.textPadding}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '800px',
                margin: '0 auto',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingTop: '56.25%',
                  width: '100%',
                }}
              >
                <iframe
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                  }}
                  src="https://www.youtube.com/embed/zFweekaWwCk"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
            <h1 css={themeStyles.blueText}>How to Clean Retainers</h1>
            <p>
              To clean your retainer, use a toothbrush and water and simply
              brush the retainers as you would brush your own teeth. This will
              be the most effective way to keep your retainers clean. You may
              soak the retainers in a non-alcohol-based mouthwash for about an
              hour a day to give it a fresh, clean taste. You can also soak your
              retainers in a denture cleaner, such as Efferdent or Retainer
              Brite, which are often available over-the-counter at local drug
              stores and pharmacies. Never boil your retainer. It is not
              heat-resistant and will melt if you try to boil it.
            </p>
            <h1 css={themeStyles.blueText}>
              What Happens if I Lose or Break My Retainers? Is it a Big Deal?
            </h1>
            <p>
              Yes, it is a big deal and here is why – your teeth will shift. I
              wish I could tell you otherwise, I really do. But the simple truth
              is that your teeth will move over time. That being said, when your
              retainer breaks, or if you lose your retainer, the best thing you
              can do for yourself is to call us immediately. If you get a new
              retainer shortly after breaking or losing it, then you will have
              nothing to worry about, and your teeth will remain in their
              current position (hopefully the same position as when you
              completed orthodontic treatment). However, if you do not act
              quickly to address the broken or loose retainer, then you run the
              risk of your teeth shifting.
            </p>
            <p>
              If you lost or broke your retainer a long time ago, there is still
              hope. You should call your orthodontist to find out what your
              options are. The orthodontist should not make you feel bad about
              it. Instead, he or she will evaluate your teeth and talk with you
              about your options. Here are the three situations you may be in
              when you lose or break your retainer:
            </p>
            <img
              src={Smile4}
              css={{
                [presets.Tablet]: {
                  float: 'right',
                  marginLeft: metrics.defaultMargin,
                  maxHeight: '650px',
                  maxWidth: '400px',
                },
                display: 'block',
                maxHeight: '400px',
                float: 'none',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
              }}
            />
            <ol css={styles.ol}>
              <li css={styles.olli}>
                <h3>
                  I broke/lost/stopped wearing my retainer a long time ago. What
                  are my options?
                </h3>
                <p>
                  Your orthodontist will first evaluate if your teeth have
                  shifted. This could be in the form of crowding, spacing,
                  rotations, overlaps, or maybe your bite just feels “off.”
                  Since it has been a long time since you have worn your
                  retainer, the chances are high that there has been some form
                  of relapse. If this is the case, your options will be to
                </p>
                <ol>
                  <li>Have limited orthodontic treatment to fix your teeth,</li>
                  <li>
                    Create a new retainer (or repair the old one) to hold the
                    current position of your teeth, or
                  </li>
                  <li>
                    Do nothing, with the risk of continued shifting of your
                    teeth.
                  </li>
                </ol>
                <p>
                  Regardless of what you decide, it is best to contact your
                  orthodontist as soon as possible to discuss your options.
                </p>
              </li>
              <li css={styles.olli}>
                <h3>
                  I broke/lost/stopped wearing my retainer recently. My teeth
                  still look fine. Do I really need to come in?
                </h3>
                <p>
                  That’s great that your teeth still look straight! However,
                  don’t plan on it for too long because your teeth will move
                  over time. If you want to keep your teeth in their current
                  positions, the easiest and least expensive option is to simply
                  call an orthodontist to have a new retainer made (or your
                  broken retainer repaired). Taking care of it now will save you
                  the trouble of needing treatment later on.
                </p>
              </li>
              <li css={styles.olli}>
                <h3>
                  My lower bonded (metal) retainer (bar) is broken/loose on the
                  inside of my bottom teeth. Can I just take it out?
                </h3>
                <p>
                  You bet you can take it out! However, you will need to wear a
                  new clear retainer that is intimately fitted to your teeth in
                  order to prevent your lower teeth from shifting. Some people
                  want to remove their metal bar to replace it with a removable
                  retainer, whereas others simply want to get the metal bar
                  re-cemented to their teeth. Regardless, it is important that
                  you do something about your broken retainer to make sure your
                  teeth do not continue to move over time.
                </p>
              </li>
            </ol>

            <h1 css={themeStyles.blueText}>
              Do I Need to Wear my Retainers for the Rest of My Life?
            </h1>
            <p>
              Yes, you do. But it won’t be as bad as you think, I promise (keep
              reading to find out why).
            </p>
            <p>
              What is important is that you understand when to wear your
              retainer and when it’s okay not to wear your retainer. Here is how
              it works – when you get your braces off, your teeth will
              immediately want to go back to their original positions.
            </p>
            <p>
              The bone surrounding your teeth is essentially what is holding
              them in place. That bone is soft at the end of treatment because
              it has been remodeled many times to allow your teeth to move to
              their new positions. Much like wet cement, your bone just needs
              time to harden around the teeth. Obviously there are many other
              biological processes going on that make the process much more
              complicated than cement drying, but the idea is very much the
              same.
            </p>
            <p>
              For that reason, you{' '}
              <strong>MUST WEAR YOUR RETAINERS FULL TIME</strong> for the first
              several weeks after your braces are removed. And by “Full Time” I
              mean a minimum of 22-23 hours per day. The only time you should be
              removing your retainers is to eat/drink and to brush your teeth.
              That’s it.
            </p>
            <p>
              Over time, we will gradually decrease the amount of time you’ll
              need to wear your retainers. After several years, it should be
              okay for you to wear your retainers only several nights a week. I
              wish I could tell you that your teeth will stay put without
              needing retainers, but I would just be leading you down a road of
              frustration and disappointment. If you currently have a retainer,
              then you will likely fall into one of the categories listed below.
              It is important for you to know what you should do when you fall
              into one of the following categories:
            </p>
            <img
              src={Smile5}
              css={{
                [presets.Tablet]: {
                  float: 'right',
                  marginLeft: metrics.defaultMargin,
                  maxHeight: '650px',
                  maxWidth: '400px',
                },
                display: 'block',
                maxHeight: '400px',
                float: 'none',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
              }}
            />
            <ul>
              <li>
                <strong>Retainers fitting well and not tight</strong> – You
                should continue to wear your retainer 2 to 4 nights per week.
              </li>
              <li>
                <strong>
                  Retainers fitting okay (i.e. they can be fully seated on your
                  teeth), but they are very tight
                </strong>
                – You must increase the use of your retainers to every night or
                even every day for a period of several weeks. The retainer can
                be used to actively “push” your teeth back to their original
                positions, but only if you are able to fully seat the retainer
                in your mouth. Once the retainer does not feel tight anymore,
                then you can decrease the use to 2 to 4 nights per week.
              </li>
              <li>
                <strong>Retainers do not fit at all</strong>– If you cannot
                fully seat your retainers, then you should call your
                orthodontist immediately. Your teeth have shifted BEYOND the
                point of using your old retainers to push them back into place.
                When you meet with your orthodontist, you will have three main
                options:
                <ol>
                  <li>
                    Do nothing. Even though this is an option, the main risk is
                    that your teeth will continue to shift over time.
                  </li>
                  <li>
                    Make a new retainer. Making a new retainer will hold the
                    CURRENT position of your teeth. It will NOT push your teeth
                    back to where they were at the end of your orthodontic
                    treatment (that’s what your old retainer could have done if
                    you were able to fully seat it), but it WILL prevent your
                    teeth from continuing to shift into worse positions.
                  </li>
                  <li>
                    Get treatment to correct the shifted teeth. This will likely
                    be in the form of braces or invisalign. If it’s just one
                    tooth out of alignment, there may even be some limited
                    treatment options with shorter treatment times. If you fall
                    into one of the above categories, or you have specific
                    questions regarding retainer care and maintenance, feel free
                    to call our office at any time.
                  </li>
                </ol>
              </li>
            </ul>

            <h1 css={themeStyles.blueText}>
              How Long do Removable Retainers Last?
            </h1>
            <p>
              On average, removable retainers should last several years. If you
              properly care for your retainers, many times your retainers will
              last much longer than expected. When your retainers begin to wear
              down or break, then you should call our office IMMEDIATELY to get
              a new set of retainers made. Do not put this off. If you do, then
              your teeth may shift and you may need treatment all over again.
            </p>

            <h1 css={themeStyles.blueText}>Bonded (Metal) Retainers</h1>
            <p>
              One of the benefits of getting a bonded metal retainer is that it
              will help hold your teeth straight. However, let’s be very clear –
              bonded retainers do NOT serve as a substitute for clear removable
              retainers (no matter what your friends or some of the “old school”
              doctors say). Some people are lucky that their bonded retainers
              work well enough to keep the teeth “mostly straight.” However,
              bonded retainers alone will not guarantee teeth to remain
              straight.
            </p>
            <p>
              For that reason, you will still be expected to wear a clear
              removable retainer over the top of your bonded retainer
              (therefore, you will still have both an upper and lower clear
              removable retainer). So if one of our doctors recommends a bonded
              retainer for you at the end of treatment, then be sure to wear
              your clear retainers as well. The Bottom Line: If you have a
              bonded (metal) lower retainer, you will STILL be expected to wear
              your upper and lower clear retainers to keep your teeth straight!
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
